import graphql from "./graphql";
import store from "../store";

async function obtenerArchivosNombreSocial(fase) {
  const query = {
    query: `
      query data ($fspCodigo: Int!){
        data: initPostulante(fspCodigo: $fspCodigo) {
          archivosNombreSocial {
            filename,
            fechaSubida,
            tipoDoc,
            originalFilename
          }

        }
      }
    `,
    variables: {
      fspCodigo: fase,
    },
  };
  const { data } = await graphql.request(query);
  return data.archivosNombreSocial;
}
async function obtenerArchivosIpe(fase) {
  const query = {
    query: `
      query data ($fspCodigo: Int!){
        data: initPostulante(fspCodigo: $fspCodigo) {
          archivosIpe {
            filename
            fechaSubida
            tipoDoc
            numeroIdentificacionOrigen
            originalFilename
          }
        }
      }
    `,
    variables: {
      fspCodigo: fase,
    },
  };
  const { data } = await graphql.request(query);
  return data.archivosIpe;
}

async function getPublicacionLocales(fspCodigo) {
  const query = {
    query: `
    query getPublicacionLocales($fspCodigo: Int!) {
      getPublicacionLocales(fspCodigo: $fspCodigo) {
        asignacion {
          localNombre
          localDireccion
          comuna
          sala
          sedeNombre
          regionNombre
        }
        esRezagado
        esBecaRechazada
      }
    }`,
    variables: {
      fspCodigo,
    },
  };
  const { getPublicacionLocales } = await graphql.request(query);
  return getPublicacionLocales;
}

//parche para cupos invierno
async function cupos() {
  const queryTotalInscritos = {
    query: `
    query totalInscritos($fspCodigo: Int!) {
      totalCupos: totalCuposFase(fspCodigo:$fspCodigo)
    }`,
    variables: {
      fspCodigo: 6,
    },
  };
  const { totalCupos } = await graphql.request(queryTotalInscritos);
  if (totalCupos <= 0 && !store.getters.esInscrito) {
    store.commit("setDialogCupos", true);
  }
  store.commit("setCuposFaseSeleccionada", totalCupos);
}

async function insertLogsAcceso(
  insTipo,
  impedimiento,
  correlativoSesion,
  permisos
) {
  const mutation = {
    query: `
    mutation insertLogAcceso($insTipo: Int!, $impedimiento: String!, $correlativoSesion: Int!) {
      insert : insertLogAcceso(insTipo: $insTipo, impedimiento: $impedimiento, correlativoSesion: $correlativoSesion )
    }`,
    variables: {
      insTipo,
      impedimiento,
      correlativoSesion,
    },
  };
  if (permisos?.some((p) => p == "escribir")) {
    await graphql.request(mutation);
  }
}

async function getAccesoEspecial(fase) {
  const query = {
    query: `
    query ($fase: Int!) {
      accesoEspecial : obtenerAccesoEspecial(fase: $fase )
    }`,
    variables: {
      fase,
    },
  };
  const { accesoEspecial } = await graphql.request(query);
  return accesoEspecial;
}

async function getBecaRechazada(fspCodigo) {
  const query = {
    query: `
    query ($fspCodigo: Int!) {
      esBecaRechazada : getBecaRechazada(fspCodigo: $fspCodigo )
    }`,
    variables: {
      fspCodigo,
    },
  };
  const { esBecaRechazada } = await graphql.request(query);
  return esBecaRechazada;
}

async function getPruebasAccesoEspecial(fase) {
  const query = {
    query: `
    query ($fase: Int!) {
      pruebasAccesoEspecial : initPruebasAccesoEspecial(fase: $fase ) {
        matematica1
        lenguage
        historia
        ciencia
        matematica2
      }
    }`,
    variables: {
      fase,
    },
  };
  const { pruebasAccesoEspecial } = await graphql.request(query);
  return pruebasAccesoEspecial;
}

async function cargaInicial() {
  const query = {
    query: `
    query getCargaInicial {
      getCargaInicial {
        postulante {
          persona {
            tipo
            numero
            nombres
            paterno
            materno
            dv
            email
          }
          escolaridad {
            curso
            rbd
            rbdDv
            codEns
            anhoEscolar
            aluPromNotasR
            situacion
            anhoEgreso
            origen
            estado
            bloqueo
            fechaProceso
            unidadEducativa {
              uedNombre
              tipoRama
              depTipo
            }
          }
          normativa {
            fspCodigo
          }
          insTipo
          beca
        }
        configuracion {
          fspCodigo
          nombreRendicion
          rdnInvierno
          procesoVigente
          inscripcionVigente
          tbkVigente
          cierreArchivo
          publicacionLocales
          finActualizacionRendicion
          inicioProceso
          finInscripcion
          becaVigente
          accesoAnticipado
          accesoRezagado
          finAccesoBecaRechazada
          nuevoPlazoBecaRechazada
        }
        pruebas {
          fspCodigo
          pruCodigo
          prueba {
            nombre
          }
        }
        licencia
        infoFases {
          faseVerano
          faseInvierno
        }
        permisos
        anioProcesoAdmision 
        correlativoSesion
        fechaCambioFinInscripcion
        cambiarFechaFinInscripcion
      }
    }`,
  };
  const { getCargaInicial } = await graphql.request(query);
  return getCargaInicial;
}

export default {
  getPublicacionLocales,
  cupos,
  insertLogsAcceso,
  getAccesoEspecial,
  cargaInicial,
  getPruebasAccesoEspecial,
  getBecaRechazada,
  obtenerArchivosNombreSocial,
  obtenerArchivosIpe,
};
